<template>
    <div>
        <div class="tweets-image">
            <img src="../../assets/img/darenxiu3.jpg"/>
        </div>



        <div class="actciles-content">
            <div class="tweets-dropdown">
                <el-dropdown size="medium" split-button type="primary" @command="changeDropdown">
                    {{ type_name }}
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item,index) in options" :command="index">{{ item.label }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="actciles-main">
                <div class="actciles-list" style="width: 100%">
                    <div class="actciles-item" v-for="(item,index) in lists" @click="toPath(item)">
                        <div class="actciles-item-img">
                            <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item.path" fit="cover" v-if="item.path"></el-image>
                        </div>
                        <div class="actciles-item-info">
                            <div class="actciles-item-title">{{ item.case_title }}</div>
                            <div class="actciles-item-intro">
                                {{ item.case_details }}
                            </div>
                            <div class="actciles-item-desc">
                                <div class="actciles-item-time">推文 {{ item.fb_time }}</div>
                                <div class="actciles-item-view" style="text-align: right">浏览 {{ item.cknum }}</div>
                            </div>
                        </div>
                    </div>

                    <no-data v-if="lists.length<=0" :txt="txt"/>
                    <div class="actciles-pagination" v-if="lists.length>0">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                :total="total"
                                :current-page="current"
                                @current-change="currentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>



        <el-dialog :visible.sync="alertVisible" title="学习联盟" width="400px" :footer="null">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/baiji/tweets`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">请先微信扫码完善个人信息</p>
            </div>
        </el-dialog>



    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    import config from '@/api/url'
    export default {
        components: {
            vueQr
        },
        data() {
            return {
                alertVisible: false,
                baseUrl: config.baseUrl,
                type_name: '筛选',
                type_id: '',
                txt: '暂无相关内容~',
                options: [
                    {
                        label: '全部',
                        value: '',
                    }, {
                        label: '晚期二线',
                        value: 1,
                    }, {
                        label: '晚期一线',
                        value: 2,
                    },
                    {
                        label: '新辅助/辅助',
                        value: 3,
                    },
                    {
                        label: '保膀胱',
                        value: 4,
                    },
                    {
                        label: 'NMIBC',
                        value: 5,
                    },
                    {
                        label: '肾癌',
                        value: 6,
                    },
                    {
                        label: '前列腺癌',
                        value: 7,
                    }
                ],
                current: 1,
                total: 0,
                lists: [],
            }
        },
        watch:{
            $route(to,from){
                if(to.query.code) {
                    setTimeout( ()=> {
                        this.getLoad();
                    },1500)
                }
            }
        },
        computed: {
            userInfo() {
                let userInfo = '';
                if(this.$store.state.userInfo && this.$store.state.userInfo != undefined){
                    userInfo = this.$store.state.userInfo
                }
                return userInfo
            },
        },
        mounted() {
            if(!this.userInfo || this.userInfo == undefined) {
                this.$store.commit('wxLogin',true);
                return false
            }
            this.getLoad()
        },
        methods: {
            getLoad() {
                this.$axios.post(this.apiUrl.is_alert, {
                    rank: this.userInfo.user.rank
                }).then(res => {
                    console.log(res)
                    if(res.code == 1) {
                        if (res.data.is_alert == 2) {
                            this.alertVisible = true;
                            this.txt = '请先微信扫码完善个人信息'
                        }else {
                            this.getList()
                        }
                    }
                })

                this.$axios.post(this.apiUrl.recordAll, { type: 1 }).then(res => {})

            },
            getList() {
                this.$axios.post(this.apiUrl.MatchTweetList, {
                    type_id: this.type_id,
                    page: this.current
                }).then(res => {
                    this.lists = res.data.list;
                    this.total = res.data.allpage;
                })
            },
            currentChange(e) {
                document.documentElement.scrollTop = 0
                this.current = e;
                this.getList();
            },
            toPath(item) {
                this.$axios.post(this.apiUrl.addMatchTweet_num, {
                    id: item.id
                }).then(res => {
                    if (res.code == 1) {
                        window.open(item.case_url)
                    } else {
                        this.$message({
                            message: res.mag,
                            type: 'warning'
                        });
                    }
                })
            },
            changeDropdown(e) {
                this.type_name = this.options[e].label;
                this.type_id = this.options[e].value;
                this.current = 1;
                this.getList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .tweets-image{
        text-align: center;
        background: #00052e;
        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }
    .tweets-dropdown{
        width: 1200px;
        margin: 50px auto 0;
    }
    .actciles-item-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .actciles-item-img{
        width: 150px!important;
        height: 150px!important;
    }
</style>
